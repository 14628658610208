import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import Team from '../components/Team'

const TeamPage = ({ data }) => {
  const intl = useIntl()
  const team = data.allContentfulTeam.edges.map(edge => {
    const { node } = edge
    return Object.assign(node, {})
  })

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'team.title' })} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'team.title' })}>
          <FormattedMessage id="team.headline" />
        </Title>
        <Team team={team} />
        <Spacer size="medium" />
      </Content>
    </Layout>
  )
}

export default TeamPage

export const i18n = {
  en: '/team',
  de: '/team',
}

export const query = graphql`
  query($i18nCurrentLocale: String!) {
    allContentfulTeam(filter: { node_locale: { eq: $i18nCurrentLocale } }) {
      edges {
        node {
          id
          name
          slug
          status {
            title
          }
          photo {
            fixed(quality: 100, width: 456) {
              src
            }
            file {
              url
            }
          }
        }
      }
    }
  }
`
