import React, { useContext } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Button from '../Button'
import title2Styles from '../../styles/title2'
import teamImg from '../../images/team-white.jpg'
import I18nContext from '../../../plugins/gatsby-plugin-i18n/utils/I18nContext'

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.primary};
  position: relative;
`

const Title = styled.h2`
  ${title2Styles}
  font-weight: normal;
  margin: 0 0 1rem 0;
`

const PlaceholderImg = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  z-index: 0;
  opacity: 0;
`

const Main = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Apply = () => {
  const i18n = useContext(I18nContext)

  const careerPage = i18n.currentLocale === 'de' ? '/de/karriere' : '/en/career'

  return (
    <Wrapper>
      <Main>
        <Title>
          <FormattedMessage id="team.apply.title" />
        </Title>
        <Button to={careerPage}>
          <FormattedMessage id="team.apply.button" />
        </Button>
      </Main>
      <PlaceholderImg src={teamImg} alt="" />
    </Wrapper>
  )
}

export default Apply
