import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { shuffle } from 'lodash'

import Profile from './Team/Profile'
import Apply from './Team/Apply'
import isPartner from '../utils/isPartner'

const Wrapper = styled.section`
  padding: 0.5rem 0 0 0;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
`

const Item = styled.div`
  width: 33.333333%;
  padding: 0 0 2rem 20px;

  @media (max-width: 680px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`

/** Shows the team overview in random order. But partners first, others second. */
// TODO: Clean-up and put into hook (used on TeamHome as well)
const Team = ({ team }) => {
  const [partners, setPartners] = useState()

  const filteredPartners = team.filter(member => isPartner(member.status.title))
  const filteredOthers = team.filter(member => !isPartner(member.status.title))

  const members = filteredPartners.concat(filteredOthers)

  // Add in the apply now box.
  members.splice(1, 0, {
    id: 'apply',
    apply: true,
  })

  useEffect(() => {
    const shuffledPartners = shuffle(filteredPartners)
    const shuffledOthers = shuffle(filteredOthers)

    const members2 = shuffledPartners.concat(shuffledOthers)

    // Add in the apply now box.
    members2.splice(1, 0, {
      id: 'apply',
      apply: true,
    })

    setPartners(members2)
  }, [])

  const finalMembers = partners || members

  return (
    <Wrapper>
      <Items>
        {finalMembers.map(member => (
          <Item key={member.id}>
            {member.apply ? <Apply /> : <Profile member={member} />}
          </Item>
        ))}
      </Items>
    </Wrapper>
  )
}

export default Team
